import Landing from '../components/Landing';
import React from 'react';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Titre RNCP de Concepteur Développeur d'Applications - RNCP37873

**Dernière mise à jour : le 20/02/2024**

La formation Développeur Web et Mobile, [à temps plein](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile/), [à temps partiel](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile-temps-partiel/) ou [en alternance](https://www.lereacteur.io/formation-alternance/concepteur-developpeur-applications/), dispensée par Le Reacteur permet de passer titre RNCP de Concepteur Développeur d'Applications de niveau 6 (Bac+3/4), dont le certificateur est le Ministère du travail, de l'emploi et de l'insertion. Sur cette page sont mentionnées les informations importantes concernant ce titre RNCP.

<p class="alert alert-info">🏆 Le taux de réussite aux sessions d’examen en 2023 et en 2024 est de 88%.<br>Les statistiques détaillées concernant les contrats en alternance seront communiquées en 2025.</p>

## Référentiel

Le titre RNCP de Concepteur Développeur d'Applications est référencé au répertoire national des certifications professionnelles, sous le numéro RNCP37873, en date du 20/07/2023. Le référentiel complet peut être consulté à l'adresse suivante : <a target="_blank"  aria-label="France Compétences" href="https://www.francecompetences.fr/recherche/rncp/37873" rel="noopener noreferrer">francecompetences.fr/recherche/rncp/37873</a>.

## Compétences attestées

L'obtention du titre RNCP permet d'attester 3 blocs de compétences en milieu professionnel :

**1. Développer une application sécurisée :**
  - Installer et configurer son environnement de travail en fonction du projet
  - Développer des interfaces utilisateur
  - Développer des composants métier
  - Contribuer à la gestion d'un projet informatique

**2. Concevoir et développer une application sécurisée organisée en couches :**
  - Analyser les besoins et maquetter une application
  - Définir l'architecture logicielle d'une application
  - Concevoir et mettre en place une base de données relationnelle
  - Développer des composants d'accès aux données SQL et NoSQL

**3. Préparer le déploiement d'une application sécurisée :**
  - Préparer et exécuter les plans de tests d'une application
  - Préparer et documenter le déploiement d'une application
  - Contribuer à la mise en production dans une démarche DevOps

##  Secteurs d'activité et type d'emploi

Une fois le titre RNCP obtenu, le candidat sera à même de travailler dans certains secteurs d'activité en lien avec ses compétences. Ceux-ci regroupent : 

- Les entreprises de services numériques (ESN) qui placent des développeurs chez leurs clients (en régie) sur des missions plus ou moins longues (3 mois renouvelables, en général).
- Les entreprises du secteur privé ou public qui ont un besoin de développement en interne : grandes entreprises ou startup.
- Les missions réalisées en free-lance dans le cas d'un statut d'auto-entrepreneur.

**Les emplois accessibles :**

- Concepteur développeur d'applications desktop
- Développeur d'applications Web, développeur d'applications Mobile
- Développeur front-end, développeur back-end
- Développeur Full-Stack
- Ingénieur d'études et développement

## Quel est le déroulé de l'examen ?

### Questionnaire professionnel (30 minutes)
Le candidat étudie une documentation technique rédigée en anglais. Il répond à :
  - deux questions fermées à choix unique posées en français ;
  - deux questions ouvertes posées en anglais et amenant des réponses courtes, en rédigeant la réponse en anglais.
  
### Présentation d’un projet réalisé en amont de la session (40 minutes)
En amont de la session, le candidat a réalisé un projet en entreprise concernant le développement d’une application Web ou mobile et a préparé un dossier de projet ainsi qu’un support de présentation (35 minutes).
  
### Entretien technique (45 minutes)
Le jury questionne le candidat sur la base de son dossier de projet et de sa présentation orale.

### Entretien final (20 minutes)
Le jury évalue la compréhension, la vision et la culture professionnelle du métier.

Durée totale de l’examen pour le candidat : 2 heures et 15 minutes

## Quels sont les prérequis au passage de l'examen du titre RNCP ?

Le titre RNCP 37873 de Concepteur Développeur d'Application est composé de 3 blocs de compétences. Ces derniers peuvent être passés/validés simultanément ou de manière indépendante, par les apprenants ayant suivi et acheté la formation Développeur Web et Mobile dispensée par Le Reacteur, mais aussi par le biais d'une VAE. Ce qui veut dire que toute personne n'ayant pas suivi une formation dans notre organisme peut tout de même se présenter à l'examen.

Vous devrez justifier d'une expérience de 6 mois minimum en tant que Développeur Web et Mobile. Cette période professionnelle peut être validée en effectuant un stage, en étant employé en CDI, en alternance ou en justifiant de 6 mois de travail en free-lance, après la formation.

Le candidat à l'examen devra également rédiger un mémoire qu'il devra soutenir lors de son examen. Ce mémoire sera exigé avant le passage du titre RNCP et remis au jury qui évaluera la soutenance. Ce mémoire devra comporter le détail de la mise en situation professionnelle du candidat et d’une présentation d’un projet Full-Stack réalisé en amont de l'examen.

Nous aidons nos apprenants à la rédaction du mémoire en leur indiquant toutes les informations nécessaires à y faire figurer, mais nous ne transmettons aucun mémoire qui aurait pu être rédigé par d'anciens candidats.

Pendant l'examen qui durera 2h15, l'apprenant sera interrogé sur son mémoire sous la forme d'un entretien technique à partir des éléments qu'il aura fournis. Le jury est composé de professionnels (2 à 3 personnes) du métier concerné par le titre.

Le Reacteur transmettra au jury les résultats des évaluations passées en cours de formation, pour les candidats ayant suivis la formation de Développeur Web et Mobile.

<p class="alert alert-info">⚠️ Il n'y a aucune équivalence et/ou passerelle (certifications professionnelles, certifications ou habilitations) permettant d'obtenir le titre RNCP de Concepteur Développeur d'Applications.</p>

## Comment valider une VAE ?

La VAE permet d'obtenir, en totalité ou en partie le titre RNCP. 12 mois d'expérience professionnelle sont nécessaires pour faire une demande de VAE. Pour comprendre ce qu'est une VAE nous vous invitons à vous rendre à l'adresse suivante : <a target="_blank" aria-label="VAE" href="https://www.education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077">education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077</a>.

## Quel est le coût du passage de l'examen du titre RNCP ?

Les apprenants qui suivent une formation délivrant un titre RNCP n'ont pas à régler quoi que ce soit pour un premier passage de l'examen.

Si le candidat échoue à son examen, et en cas de repassage, ce dernier devra s'acquitter des frais relatifs au montage du dossier auprès du Ministère du travail de l'emploi et de l'insertion, ainsi qu'à la rémunération du jury ; le montant s'élève à 600 euros et 1.200 euros pour une VAE.

Ces frais seront également exigés aux candidats souhaitant passer une VAE.

## Quelle est la procédure pour passer le titre RNCP ?

Tout d'abord, il conviendra de [nous contacter par mail](https://www.lereacteur.io/contact/) pour nous informer de votre souhait de passer l'examen.

À la suite de ça, nous vous proposerons, une date de passage de l'examen. Il y a deux sessions d'examen par an qui se déroulent respectivement en août et septembre. Une fois la date de votre passage validée, votre mémoire devra être transmis au jury avant la date de l'examen (nous nous chargerons de cette procédure). À partir de là, le mémoire ne pourra plus être modifié.

Vous recevrez une convocation pour passer l'examen. Tout retard le jour de l'examen est disqualifiant.

Une fois l'examen passé, le jury aura 3 semaines pour délibérer et nous transmettre sa décision. Vous serez informé par mail du résultat de l'examen et, en cas de réussite, nous vous remettrons votre titre RNCP.

## Passer son titre RNCP de niveau 6 (Bac+3/4) pendant l'alternance

Le Reacteur vous permet de suivre la formation de Développeur Web et Mobile en alternance. Pendant ce cursus qui dure 15 à 17 mois, vous alternerez entre périodes de formation et périodes en entreprise. À la fin de votre cursus, vous passerez l'examen du titre RNCP de Concepteur Développeur d'Applications. 9 jours sont prévus pour que vous puissiez travailler sur votre mémoire ainsi que pour finaliser vos projets à réaliser, pour le passage de l'examen.

## Aménagements pour les personnes en situation de handicap

Toute personne en situation de handicap se verra proposer des solutions visant à lui faciliter le passage de l'examen du titre RNCP. Si le candidat a des difficultés pour se déplacer sur le lieu de l'examen, il lui sera proposé une évaluation à distance, grâce à la visio-conférence (Zoom ou équivalent). Notre équipe rentre en relation avec tous les candidats au titre RNCP, avant de valider une date de passage. C'est à ce moment là que les apprenants sont informés des aménagements éventuels, en fonction de la situation du candidat. Notre équipe attache une importance particulière à l'égalité des droits et des chances ; notre souhait est de faciliter le passage de l'examen.

Si vous pensez que cela vous concerne, vous devrez justifier de votre situation de handicap en nous fournissant tout document administratif délivré par une autorité compétente (RQTH, AAH…).

Notre contact référent handicap : **contact**[arobase]**lereacteur**[point]**io**.

`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Titre RNCP de Concepteur Développeur d'Applications - RNCP37873"
      metaTitle="Titre RNCP de Concepteur Développeur d'Applications | Le Reacteur"
    />
  );
});

export default Page;
